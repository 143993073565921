import React from 'react'

import { LanguageSelector } from 'components/ageGate/components/LanguageSelector'
import { TranslatedLinkMessage } from 'components/ageGate/components/TranslatedLinkMessage'
import { TranslatedTextMessage } from 'components/ageGate/components/TranslatedTextMessage'

export const DefaultFooter = (props) => {
  const {
    config: {
      intellectualPropertyWarning,
      resellerWarning,
      warrantySupport,
      avRequired,
      languageSelector,
    },
  } = props

  return (
    <>
      {resellerWarning && (
        <p>
          <TranslatedLinkMessage
            id={'RESELLER_WARNING_TEXT'}
            link={'RESELLER_WARNING_URL'}
            linkText={'RESELLER_WARNING_LINK_TEXT'}
          />
        </p>
      )}
      {warrantySupport && (
        <p>
          <TranslatedLinkMessage
            id={'WARRANTY_SUPPORT_TEXT'}
            link={'WARRANTY_SUPPORT_URL'}
            linkText={'WARRANTY_SUPPORT_LINK_TEXT'}
          />
        </p>
      )}
      {intellectualPropertyWarning && (
        <p>
          <TranslatedLinkMessage
            id={'INTELLECTUAL_PROPERTY_WARNING_TEXT'}
            link={'INTELLECTUAL_PROPERTY_WARNING_URL'}
            linkText={'INTELLECTUAL_PROPERTY_WARNING_LINK_TEXT'}
          />
        </p>
      )}
      {avRequired && (
        <p>
          <TranslatedTextMessage id={'AV_REQUIRED'} />
        </p>
      )}
      {languageSelector?.languages?.length > 1 && (
        <LanguageSelector
          languages={languageSelector.languages}
        />
      )}
    </>
  )
}
