import React, { FC } from 'react'

import { useIntl } from 'react-intl'

import { AgeGateContext } from 'components/ageGate/contexts/AgeGateContext'
import { LocalizedLink } from 'components/common/LocalizedLink'
import { TranslatedMessage } from 'components/common/TranslatedMessage'

type Props = {
  id: string
  inlineData?: Record<string, unknown>
  link: string
  linkText: string
}

const getLink = (translationMessages, inputUrl, inputLinkText) => {
  const urlMessage = translationMessages[inputUrl]
  const linkTextMessage = translationMessages[inputLinkText]
  return <LocalizedLink href={urlMessage}>{linkTextMessage}</LocalizedLink>
}

export const TranslatedLinkMessage: FC<Props> =
  ({ id, link, linkText, inlineData }) => {
    const { messages } = useIntl()
    const { messageKeys } = React.useContext(AgeGateContext)

    return (
      <TranslatedMessage
        id={messageKeys[id]}
        values={{
          link_text: getLink(
            messages,
            messageKeys[link],
            messageKeys[linkText],
          ),
          ...inlineData,
        }}
      />
    )
  }
