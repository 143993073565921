import React, { useEffect, useState } from 'react'

import { AgeGateUIPolicy, ConfigBody, StateListItem } from '@viewlio/types/src/AgeGate'
import cx from 'classnames'
import colorStyles from 'styles/common/color.module.scss'

import { AgreeButton } from 'components/ageGate/components/AgreeButton'
import { BlockedStateWarningText } from 'components/ageGate/components/BlockedStateWarningText'
import { DisagreeButton } from 'components/ageGate/components/DisagreeButton'
import {
  StateSelector,
} from 'components/ageGate/components/StateSelector'
import { TranslatedTextMessage } from 'components/ageGate/components/TranslatedTextMessage'

import commonStyles from '../common.module.scss'

type Props = {
  ageGateUIPolicy: AgeGateUIPolicy
  config: ConfigBody
  isGeolocationLoading: boolean
  onAgreeButtonClick(stateCode: string): void
  onRouteToLocatorButtonClick(stateCode: string): void
  shouldTrackAgeGateEvents: boolean
}

export const DefaultBody: React.FC<Props> = ({
  ageGateUIPolicy,
  config: { minimumAge, agree, disagree, selector },
  isGeolocationLoading,
  shouldTrackAgeGateEvents,
  onAgreeButtonClick,
  onRouteToLocatorButtonClick,
}) => {
  const { getInitialGeoState } = ageGateUIPolicy
  const [selectedState, setSelectedState] = useState(getInitialGeoState())
  const [submitErrorKey, setSubmitErrorKey] = useState(null)

  useEffect(() => {
    if (isGeolocationLoading === false && !selectedState.value) {
      setSelectedState(getInitialGeoState())
    }
  }, [isGeolocationLoading])

  const handleStateSelectionChange = (state: StateListItem) => {
    if (state) {
      setSelectedState(state)
    }
  }

  const submitError = () => {
    if (selector && selectedState.value === '') {
      setSubmitErrorKey('STATE_AGE_GATE_SUBMIT_ERROR_STATE_MISSING')
      return true
    }

    setSubmitErrorKey(null)
    return false
  }

  const handleRouteToLocatorTrigger = () => {
    if (submitError()) {
      return false
    }
    onRouteToLocatorButtonClick(selectedState.value)
  }

  const handleAgreeButtonTrigger = () => {
    if (submitError()) {
      return false
    }
    onAgreeButtonClick(selectedState.value)
  }

  const decideAgreeButtonProps = () => {
    if (ageGateUIPolicy.shouldShowRouteToLocatorButton(selectedState)) {
      return {
        disabled: false,
        isLoading: isGeolocationLoading,
        messageId: 'ROUTE_TO_LOCATOR_TEXT',
        onClick: handleRouteToLocatorTrigger,
      }
    }
    return {
      disabled: ageGateUIPolicy.shouldDisableAgreeButton(selectedState),
      isLoading: isGeolocationLoading,
      messageId: 'AGREE_TEXT',
      onClick: handleAgreeButtonTrigger,
    }
  }

  return (
    <div
      data-testid='age-gate-body-container'
      id='age-gate-body-container'
    >
      <div
        className={commonStyles.subsection}
        data-testid='age-gate__selector-subsection'>
        {selector && (
          <StateSelector
            isLoading={isGeolocationLoading}
            selector={selector}
            selectedState={selectedState}
            callback={handleStateSelectionChange}
          />
        )}
        {ageGateUIPolicy.shouldShowBlockedStateWarning(selectedState) && (
          <BlockedStateWarningText
            stateLabel={selectedState.label}
            messageKey='BLOCKED_STATE_WARNING'
          />
        )}
      </div>
      <div
        data-testid='age-gate__buttons-subsection'
      >
        {submitErrorKey && (
          <div className={cx(commonStyles.subsection, colorStyles.red)}>
            <TranslatedTextMessage id={submitErrorKey} />
          </div>
        )}
        {agree && (
          <AgreeButton minimumAge={minimumAge} {...decideAgreeButtonProps()} />
        )}
        {disagree && (
          <DisagreeButton
            minimumAge={minimumAge}
            shouldTrackAgeGateEvents={shouldTrackAgeGateEvents}
          />
        )}
      </div>
    </div>
  )
}
