import React, { FC } from 'react'

import { Button } from '@juullabs/react-components'
import { FormattedHTMLMessage } from 'react-intl'

import { AgeGateContext } from 'components/ageGate/contexts/AgeGateContext'

import styles from './AgreeButton.module.scss'

type Props = {
  disabled?: boolean
  isLoading: boolean
  messageId: string
  minimumAge: number
  onClick?(): void
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type']
}

export const AgreeButton: FC<Props> = ({
  onClick,
  messageId,
  minimumAge,
  disabled = false,
  isLoading,
  type,
}) => {
  const { messageKeys } = React.useContext(AgeGateContext)

  const buttonText = (
    <FormattedHTMLMessage
      id={messageKeys[messageId]}
      values={{
        age: minimumAge,
      }}
    />
  )

  return (
    <Button
      type={type}
      id='age-gate-confirmation'
      className={styles.button}
      onClick={onClick}
      disabled={isLoading || disabled}
      processing={isLoading}
    >
      {buttonText}
    </Button>
  )
}
